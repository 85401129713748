<template>
    <div class="mb-3 px-3">
        <div v-show="showIndex==0">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.ExternalYardReception')}`}} </h6>
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12" lg="12" class="align-items-end mb-3">
                    <CButton
                        shape="square"
                        color="add"
                        class="d-flex align-items-center ml-auto"
                        v-c-tooltip="{
                            content: $t('label.Entry'),
                            placement: 'top-end'
                        }"
                        @click="showIndex=1"
                    >
                        <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
                    </CButton>
                </CCol>
            </CRow>
            <CRow class="mt-2">
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                
                    <template #options="{ item }">
                        <td class="text-center">
                            <CButton
                                square
                                size="sm"
                                color="watch"
                                class="mr-1"
                                @click="toggleAdd(item)"
                                v-c-tooltip="{
                                    content: $t('label.reception'),
                                    placement: 'top-start'
                                }"
                            >
                                <CIcon name='cil-list' />
                            </CButton>
                            <CButton
                              color="edit"
                              square
                              size="sm"
                              v-c-tooltip="{
                                content: `${$t('label.edit')} ${$t('label.Entry')}`,
                                placement: 'top-start'
                              }"
                              @click="EditEntry(item)"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1">
            <NewEntry
                :Active="showIndex==1"
                :ContainerInfo="ContainerInfo"
                :isEdit="isEdit"
                @Close="showIndex=0, ContainerInfo={}, isEdit = false"
                @Update-list="getYardCargoExternPending"
            />
        </div>
        <div v-show="showIndex==2">
            <PendingRegister
				:Active="showIndex== 2"
				:ContainerItem="ContainerInfo"
				@Close="showIndex = 0, ContainerInfo={}"
				@Update-list="Update"
			/>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import NewEntry from './new-entry';
import PendingRegister from "./pending-register";
import { DateFormater } from '@/_helpers/funciones';
  
//data
function data() {
    return {
        isEdit: false,
        Items: [],
        ContainerInfo:{},
        dataContainer: [],
        showIndex: 0,
    };
}

//methods
async function getYardCargoExternPending(loading) {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get('YardCargoExternPending-list', { CompanyBranchId: this.CompanyBranchId })
        .then(response => {
            let List = response.data.data;
            this.dataContainer = List&&List.length!=0 ? List : [];
        })
        .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = loading;
            if (this.FgPositioning) {
                this.$store.state.yardManagement.ContainerTabIndex = 2;
                this.$store.state.yardManagement.dropContainerMovement = 3;
                this.$store.state.yardManagement.yardCollapse = 2;
            }
        });
}

async function Update(loading) {
    await this.getYardCargoExternPending(loading);
}

function toggleAdd(item) {
    this.ContainerInfo = item;
    this.showIndex = 2;
}

function EditEntry(item) {
    this.ContainerInfo = item;
    this.isEdit = true;
    this.showIndex = 1;
}

function Reset() {
    this.dataContainer = [];
    this.ContainerInfo = {};
    this.isEdit = false;
    this.showIndex = 0;
}
  
//computed
function computedListContainer() {
    return this.dataContainer.map((item) => {
        let CargoInfoJson = item.CargoInfoJson[0] ?? {};
        let CargoMasterJson = CargoInfoJson.CargoMasterJson[0] ?? {};
        return {
            Nro: item.IdX,
            CargoId: item.CargoId,
            TpCargoDetailId: CargoInfoJson.TpCargoDetailId,
            ContainerCode: CargoInfoJson.ContainerCode,
            ItineraryId: CargoMasterJson.ItineraryId,
            VesselName: CargoMasterJson.VesselName ?? '',
            Voyage: CargoMasterJson.Voyage ?? '',
            VesselVoyage: CargoMasterJson.ItineraryId ? `${CargoMasterJson.VesselName} - ${CargoMasterJson.Voyage}` : '',
            Arrival: CargoMasterJson.Arrival ?? '',
            Departure: CargoMasterJson.Departure ?? '',
            LabelArrival: CargoMasterJson.LabelArrival ?? '',
            LabelDeparture: CargoMasterJson.LabelDeparture ?? '',
            AtaEta: `${CargoMasterJson.Arrival ? DateFormater.formatDateTimeWithSlash(CargoMasterJson.Arrival) : ''}`,
            TpCargoStatusId: CargoMasterJson.TpCargoStatusId ?? '',
            TpCargoStatusName: CargoMasterJson.TpCargoStatusName ?? '',
            TpCargoDetailCode: CargoInfoJson.TpCargoDetailCode ?? '',
            ShippingLineName: CargoMasterJson.ShippingLineName ?? '',
            ClientName: CargoMasterJson.ClientName ?? '',
            ImdgCode: CargoMasterJson.ImdgCode ?? '',
            Status: CargoMasterJson.FgActCargoMaster ? 1 : 0,
            TransaLogin: CargoMasterJson.TransaLogin ?? '',
            CumputedDate: CargoMasterJson.TransaRegDate ? DateFormater.formatDateTimeWithSlash(CargoMasterJson.TransaRegDate) : '',
        };
    })
}

function fields(){ 
    return [
        { key: 'options', label: '', _style: 'min-width:90px;', sorter: false, filter: false },
        { key: 'Nro', label: '#', _style: 'text-align:center', _classes: 'text-center', filter: false },
        { key: 'ContainerCode', label: this.$t('label.container'),  _classes: 'text-uppercase text-center', _style: 'min-width: 170px; text-align:center;', sorter: true, filter: true},
        { key: 'VesselVoyage', label: `${this.$t('label.visit')} / ${this.$t('label.itinerary')}`,  _classes: 'text-uppercase text-center', _style: 'min-width: 170px; text-align:center;', sorter: true, filter: true},
        { key: 'AtaEta', label: 'ATA / ETA',  _classes: 'text-uppercase text-center', _style: 'min-width: 130px;  text-align:center;', sorter: true, filter: true},
        { key: 'TpCargoStatusName', label: `${this.$t('label.status')}`, _style:'min-width: 120px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
        { key: 'TpCargoDetailCode', label: `${this.$t('label.type')}`, _style:'min-width: 120px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
        { key: 'ShippingLineName', label: this.$t('label.newShippingLine'),  _classes: 'text-uppercase text-center', _style: 'min-width: 170px;  text-align:center;', sorter: true, filter: true},
        { key: 'ClientName', label: this.$t('label.client'), _style:'min-width: 170px; text-align:center;', _classes:"text-center", sorter: true, filter: true},
        { key: 'ImdgCode', label: this.$t('label.imdg'), _style:'min-width: 130px; text-align:center;', _classes:"text-center", sorter: true, filter: true},
        { key: 'TransaLogin', label: this.$t('label.user'), _classes: 'text-center', _style: 'min-width: 170px;'},
        { key: 'CumputedDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
    ];
}

export default{
    name: 'pending',
    data,
    mixins: [General],
    components: {
        NewEntry,
        PendingRegister,
    },
    methods: {
        toggleAdd,
        EditEntry,
        getYardCargoExternPending,
        Update,
        Reset,
    },
    computed: {
        fields,
        computedListContainer,
        ...mapState({
            CompanyBranchId: state => state.auth.branch.CompanyBranchId,
            YardId: state => state.yardManagement.yardData.YardId,
            FgPositioning: state => state.yardManagement.FgPositioning,
            dropItemMovementList: state => state.yardManagement.dropItemMovementList,
        })
    },
    watch:{
        dropItemMovementList: async function (val) {
            if (val==8) {
                await this.Reset();
                this.getYardCargoExternPending(false);
            }
        }
    }
}
</script>