var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 px-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==0),expression:"showIndex==0"}]},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"12","xl":"12"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.ExternalYardReception')}`)+" ")],1)])],1),_c('CRow',[_c('CCol',{staticClass:"align-items-end mb-3",attrs:{"sm":"12","lg":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.Entry'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.Entry'),\n                            placement: 'top-end'\n                        }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":function($event){_vm.showIndex=1}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedListContainer,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                    content: _vm.$t('label.reception'),
                                    placement: 'top-start'
                                }),expression:"{\n                                    content: $t('label.reception'),\n                                    placement: 'top-start'\n                                }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.toggleAdd(item)}}},[_c('CIcon',{attrs:{"name":"cil-list"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: `${_vm.$t('label.edit')} ${_vm.$t('label.Entry')}`,
                                placement: 'top-start'
                              }),expression:"{\n                                content: `${$t('label.edit')} ${$t('label.Entry')}`,\n                                placement: 'top-start'\n                              }"}],attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.EditEntry(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==1),expression:"showIndex==1"}]},[_c('NewEntry',{attrs:{"Active":_vm.showIndex==1,"ContainerInfo":_vm.ContainerInfo,"isEdit":_vm.isEdit},on:{"Close":function($event){_vm.showIndex=0, _vm.ContainerInfo={}, _vm.isEdit = false},"Update-list":_vm.getYardCargoExternPending}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==2),expression:"showIndex==2"}]},[_c('PendingRegister',{attrs:{"Active":_vm.showIndex== 2,"ContainerItem":_vm.ContainerInfo},on:{"Close":function($event){_vm.showIndex = 0, _vm.ContainerInfo={}},"Update-list":_vm.Update}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }